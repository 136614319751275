import React, { useState } from 'react';
import styles from './styles.module.css';

function SearchBar({ onSubmit }) {
  const [topic, setTopic] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    console.log('topic', topic)
    onSubmit(topic);
  }

  return (
    <div className={styles.searchContainer}>
      <div className={styles.learnText}>I want to learn</div>
      <form onSubmit={handleSubmit} className={styles.searchForm}>
        <input
          type="text"
          value={topic}
          onChange={(event) => setTopic(event.target.value)}
          placeholder="eg, machine learning"
          className={styles.searchInput}
        />
        <button type="submit" className={styles.searchButton}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default SearchBar;






