import { makeendpoint } from "./endpoint";
import React, { useRef, useEffect } from 'react';



export interface NodeToolbarProps {
  nodeId: string;
  addNode: (data:any[]) => void;
  close: () => void;
}
const NodeToolbarComponent = ({ nodeId, addNode, close }: NodeToolbarProps) => {
  const toolbarRef = useRef<HTMLDivElement | null>(null);



  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (toolbarRef.current && !toolbarRef.current.contains(event.target as Node)) {
        close();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [close]);


  const handleSubtopicsClick = async () => {
    close();
    const response = await fetch(makeendpoint('get-subtopics'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ node_id: nodeId }),
    });

    const subtopics = await response.json();
    console.log(subtopics);
    // subtopics.forEach((subtopic:any) => addNode(subtopic.id, subtopic));
    addNode(subtopics.map((subtopic:any) => ({id: subtopic.id, label: subtopic.subtopic, parentNodeId: subtopic.parent_node_id})));
  };

  const handleResourcesClick = async () => {
    const response = await fetch(makeendpoint('get-resources'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ node_id: nodeId }),
    });
    const resources = await response.json();
    // resources.forEach((resource:any) => addNode(resource.id, resource));
    addNode(resources.map((subtopic:any) => ({id: resources.id, label: resources.resource, parentNodeId: resources.parent_node_id})));
  };

  const handleQuestionsClick = async () => {
    const response = await fetch(makeendpoint('get-questions'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ node_id: nodeId }),
    });
    const questions = await response.json();
    // questions.forEach((question:any) => addNode(question.id, question));
    addNode(questions.map((subtopic:any) => ({id: questions.id, label: questions.question, parentNodeId: questions.parent_node_id})));
  };

  const handleSummaryClick = async () => {
    const response = await fetch(makeendpoint('get-summary'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ node_id: nodeId }),
    });
    const summary = await response.json();
    // addNode(summary.id, summary);
    addNode([{id: summary.id, label: summary.summary, parentNodeId: summary.parent_node_id}]);
  };

  return (
    <div ref={toolbarRef}>
  <div className="w-full max-w-xs mx-auto mt-5">
  <input type="text" placeholder="Search..." className="w-full px-3 py-2 mb-3 text-gray-700 border rounded-lg focus:outline-none" />
  <div className="relative">
    <select
      onChange={(e) => {
        switch (e.target.value) {
          case "subtopics":
            handleSubtopicsClick();
            break;
          case "resources":
            handleResourcesClick();
            break;
          case "questions":
            handleQuestionsClick();
            break;
          case "summary":
            handleSummaryClick();
            break;
          default:
            break;
        }
      }}
      className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none appearance-none"
    >
      <option value="" hidden>Select an action...</option>
      <option value="subtopics">Expand with Subtopics</option>
      <option value="resources">Expand with Resources</option>
      <option value="questions">Expand with Questions</option>
      <option value="summary">Summarize This Topic</option>
    </select>
    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
      <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
        <path d="M10 12l5-6H5l5 6z" />
      </svg>
    </div>
  </div>
</div>


      {/* <button onClick={handleSubtopicsClick}>Expand with Subtopics</button>
      <button onClick={handleResourcesClick}>Expand with Resources</button>
      <button onClick={handleQuestionsClick}>Expand with Questions</button>
      <button onClick={handleSummaryClick}>Summarize This Topic</button> */}
    </div>
  );
};

export default NodeToolbarComponent;


