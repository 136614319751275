import React, { useState, DragEvent, useEffect } from 'react';
import { Handle, NodeProps, Position, NodeResizer, NodeToolbar } from 'reactflow';
import cx from 'classnames';
import NodeToolbarComponent from './NodeToolbar';

import styles from './styles.module.css';

export interface CustomNodeProps extends NodeProps {
  addNode: (id: string, label: string) => void;
}


  function CustomNode({ data, id }: NodeProps) {
    const { label, addNode } = data;
    console.log('CustomNode', data);

  const [isDropzoneActive, setDropzoneActive] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState<boolean>(false);

  const onDrop = () => {
    setDropzoneActive(false);
  };

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
  };

  const onDragEnter = () => {
    setDropzoneActive(true);
  };

  const onDragLeave = () => {
    setDropzoneActive(false);
  };

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSourceClick = () => {
    console.log('handleSourceClick');
    setIsToolbarVisible(!isToolbarVisible);
  };



  const className = cx(styles.node, { [styles.nodeDropzone]: isDropzoneActive, [styles.nodeHover]: isHovered });

  return (
    <div
      className={className}
      onDrop={onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Handle className={styles.handle} type="target" position={Position.Left} />
      <Handle className={styles.sourceHandle} type="source" position={Position.Right} onClick={handleSourceClick} />

      {/* The NodeToolbar is shown when source handle is clicked */}
      {isToolbarVisible && (
        <NodeToolbar nodeId={id} isVisible={isToolbarVisible} position={data.toolbarPosition || Position.Right}>
          <NodeToolbarComponent nodeId={id} addNode={addNode} close={() => setIsToolbarVisible(false)}/>
        </NodeToolbar>
      )}


      {data.label}

    </div>
  );
}

export default CustomNode;


