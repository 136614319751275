export function makeendpoint(path: string) {
    if (window.location.href.includes("3000")){

        return `http://localhost:8000/api/${path}`;
    }

    return `https://scipeer.xyz/api/${path}`;

}

// export const endpoint = 'https://scipeer.xyz'
export const endpoint = 'http://localhost:8000'

export default endpoint;