import React, { useEffect, useState, MouseEvent, DragEvent, DragEventHandler } from 'react';
import ReactFlow, {
  MarkerType,
  ReactFlowProvider,
  useReactFlow,
  Node,
  Edge,
  NodeTypes,
  OnNodesChange,
  applyNodeChanges,
  NodeMouseHandler,
  NodeChange,
  OnEdgesChange,
  EdgeChange,
  applyEdgeChanges,
} from 'reactflow';
import axios from 'axios';
import Sidebar from './Sidebar';
import CustomNode from './CustomNode';
import useAutoLayout, { Direction } from './useAutoLayout';
import SearchBar from './SearchBar';
import { Spinner } from 'react-bootstrap';
import './spinner.css';
import endpoint from './endpoint';
// import useGoogleAnalytics from './useGoogleAnalytics';
import { Analytics } from '@vercel/analytics/react';
import Drawer from '@mui/material/Drawer';

import 'reactflow/dist/style.css';
import styles from './styles.module.css';

const nodeTypes: NodeTypes = {
	custom: CustomNode,
  };

  const proOptions = {
	account: 'paid-pro',
	hideAttribution: true,
  };

  const defaultEdgeOptions = {
	type: 'smoothstep',
	markerEnd: { type: MarkerType.ArrowClosed },
	pathOptions: { offset: 5 },
  };

  type ExampleProps = {
	direction?: Direction;
  };

  type NodeData = {
	label: string;
	type: string;
	resource?: {
	  title: string;
	  link: string;
	  type: string;
	};
  };

  function ReactFlowPro({ direction = 'LR', searchInput }: ExampleProps & { searchInput: string }) {
	const { fitView } = useReactFlow();
	useAutoLayout({ direction });
	const [nodes, setNodes] = useState<(Node)[]>([]);
	const [edges, setEdges] = useState<(Edge)[]>([]);
	const [loading, setLoading] = useState(false);
	const [drawerVisible, setDrawerVisible] = useState(false);
    const [resources, setResources] = useState([]);


	useEffect(() => {
	  if (searchInput) {
		fetchTopicId(searchInput);
	  }
	}, [searchInput]);

	const fetchTopicId = async (topic: string) => {
	  setLoading(true);
	  try {
		const response = await axios.post('http://localhost:8000/api/create-topic', {
		  topic: topic,
		});
		const topicId = response.data.topic_id;
		const newNode = {
		  id: topicId,
		  type: 'custom',
		  data: { label: topic },
		  position: { x: 250, y: 250 },
		};
		setNodes([newNode]);
	  } catch (error) {
		console.error('Error fetching topic ID:', error);
	  } finally {
		setLoading(false);
	  }
	};

	const fetchSubtopics = async (nodeId: string) => {
	  setLoading(true);
	  try {
		const response = await axios.post('http://localhost:8000/api/get-subtopics', {
		  node_id: nodeId,
		});
		const subtopics = response.data;
		const newNodes = subtopics.map((subtopic: any) => ({
		  id: subtopic.id,
		  type: 'custom',
		  data: { label: subtopic.subtopic },
		  position: { x: 0, y: 0 },
		}));
		const newEdges = subtopics.map((subtopic: any) => ({
		  id: `${nodeId}-${subtopic.id}`,
		  source: nodeId,
		  target: subtopic.id,
		}));
		setNodes((prevNodes) => [...prevNodes, ...newNodes]);
		setEdges((prevEdges) => [...prevEdges, ...newEdges]);
	  } catch (error) {
		console.error('Error fetching subtopics:', error);
	  } finally {
		setLoading(false);
	  }
	};

	const fetchResources = async (nodeId: string) => {
		try {
		  const response = await axios.post('http://localhost:8000/api/get-resources', {
			node_id: nodeId,
		  });
		  setResources(response.data);
		} catch (error) {
		  console.error('Error fetching resources:', error);
		}
	  };


	const onNodeClick: NodeMouseHandler = (_: MouseEvent, node: Node<NodeData>) => {
	  if (node.type === "custom" && !node.data.resource) {
		fetchSubtopics(node.id);

		 // Fetch resources for the clicked node
		 fetchResources(node.id);
		 setDrawerVisible(true);
	  }
	};

	const onNodesChange: OnNodesChange = (changes: NodeChange[]) => {
	  setNodes((nodes) => applyNodeChanges(changes, nodes));
	};

	const onEdgesChange: OnEdgesChange = (changes: EdgeChange[]) => {
	  setEdges((edges) => applyEdgeChanges(changes, edges));
	};

	useEffect(() => {
	  fitView({ duration: 400 });
	}, [nodes, fitView]);

	return (
	  <div className={styles.container}>
		<ReactFlow
		  className={styles.reactFlow}
		  proOptions={proOptions}
		  nodeTypes={nodeTypes}
		  nodes={nodes}
		  edges={edges}
		  onNodesChange={onNodesChange}
		  onEdgesChange={onEdgesChange}
		  fitView
		  onNodeClick={onNodeClick}
		  defaultEdgeOptions={defaultEdgeOptions}
		  minZoom={-Infinity}
		  maxZoom={Infinity}
		/>
		<Drawer
  anchor="right"
  open={drawerVisible}
  onClose={() => setDrawerVisible(false)}
>
  <div style={{ width: 250, padding: 20 }}>
    <h3>Resources</h3>
    {resources.length === 0 ? (
      <p>No resources available.</p>
    ) : (
      <ul>
        {resources.map((resource: any) => (
          <li key={resource.id}>{resource.resource}</li>
        ))}
      </ul>
    )}
  </div>
</Drawer>
		{loading && (
		  <Spinner
			animation="border"
			role="status"
			style={{
			  position: "absolute",
			  top: "10%",
			  left: "50%",
			  transform: "translate(-50%, -50%)",
			  borderColor: "white",
			  borderStyle: "solid",
			  borderWidth: "0.2em",
			  borderTopColor: "transparent",
			  borderRadius: "50%",
			  width: "2em",
			  height: "2em",
			  animation: "spin 1s linear infinite",
			}}
		  >
			<span className="sr-only">Loading...</span>
		  </Spinner>
		)}
	  </div>
	);
  }

  const ReactFlowWrapper = (props: ExampleProps) => {
	const [searchInput, setSearchInput] = useState('');

	const handleSearchSubmit = (searchTerm: string) => {
	  setSearchInput(searchTerm);
	};

	return (
	  <div style={{ height: '100vh' }}>
		{!searchInput && <SearchBar onSubmit={handleSearchSubmit} />}
		{searchInput && (
		  <ReactFlowProvider>
			<ReactFlowPro {...props} searchInput={searchInput} />
		  </ReactFlowProvider>
		)}
		<Analytics />
	  </div>
	);
  };

  export default ReactFlowWrapper;
  // export default () => (
  //   <ReactFlowProvider>
  //     <App />
  //   </ReactFlowProvider>
  // );

  // export default () => (
  //   <ReactFlowProvider>
  //     <App />
  //   </ReactFlowProvider>
  // );

